<template>
  <div class="but_shop_box flex" v-loading.fullscreen.lock="fullscreenLoading">
    <!-- {{ content }} -->
    <div class="shop_length" v-if="$store.state.shopObj.length != 0">
      <p class="shop_length_title" @click.stop="shopFn">
        已选-资源列表: {{ $store.state.shopObj.length }}
      </p>
      <div class="shop_particulars_box" v-show="shopShow">
        <div class="shop_particulars_ct_box flex">
          <p>已选资源 {{ $store.state.shopObj.length }}个</p>
          <p>总金额:￥{{ totalMoney }}元</p>
        </div>
        <!-- <img :src=`../${img}` alt=""> -->
        <ul class="shop_list">
          <li v-for="(item, index) in $store.state.shopObj" :key="index" class="flex">
            <p class="title flex">
              <!-- <img :src="item.logo" alt="" /> -->
              <span>{{ item.title }}</span>

            </p>

            <p class="price"><span class="price" v-show="item.check">{{
                item.check ==
                'member_image_text_price'
                    ?
                    '图文价格' : item.check ==
                    'member_video_price' ? '视频价格' : item.check ==
                    'direct_price' ? '直发价格' : item.check ==
                    'member_forward_price' ? '转发价格' : item.check ==
                    'member_headline_price' ? '头条价格' : item.check == 'ordinary_price' ? '普条价格' : ''
              }}</span>￥{{
                item.medium_price.toFixed(2)
              }}</p>
            <i class="delete" @click.stop="deleteFn(index)"></i>
          </li>
        </ul>
        <div class="flex" style="justify-content: flex-end">
          <button class="close" @click.stop="shopShow = false">关闭</button>
        </div>
      </div>
    </div>

    <div class="price_box flex" v-if="$store.state.shopObj.length != 0">
      <p class="money" style="margin-right: 20px;">
        账户余额: <span>￥{{ $user_info.money }}</span>元
      </p>
      <p class="price">
        总价: <span>￥{{ totalMoney }}</span>元
      </p>

      <button v-if="$route.name == 'fill' || $route.name == 'or_fill'" class="preview" @click.stop="subFn1">
        预览文章并发布
      </button>
      <!-- <button v-if="$route.name == 'fill'" class="sub" @click.stop="subFn1">
        确认下单
      </button> -->
      <button v-if="$route.name == 'ManuscriptPreview'" class="sub" :disabled="unUse" @click.stop="subFn2">
        确认下单
      </button>
      <!-- <button v-if="$route.name == 'ManuscriptDetails'" class="sub" @click.stop="subFn3">
        立即支付
      </button> -->
    </div>
    <div class="shop_length" v-if="$store.state.shopObj.length == 0">

    </div>
    <div class="price_box flex" v-if="$store.state.shopObj.length == 0">
      <button class="sub" @click.stop="subFn3">
        选择媒介
      </button>
    </div>
    <!-- 遮罩层 -->
    <div class="MaskLayer" @click="shopShow = !shopShow" v-show="shopShow"></div>
    <Popup @close="close" :visible="popupVisible" :content="popupContent" :type="1" :id="$route.query.id"/>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex'
import Popup from '@/components/sensitivityPopup'
import {empty} from '@/util/emptyFillDate'
import {fill_title_rules} from '@/util/fill_title_rules'

export default {
  name: '',
  computed: {
    ...mapState(['shopObj', 'FillForm', 'UserTypeItem', 'fillActive', 'mediaOrder']),
    ...mapMutations(['SETACTIVEMENU']),
    totalMoney: function () {
      var n = 0;
      this.$store.state.shopObj.forEach((item, index) => {
        if (typeof (this.$store.state.shopObj[index].medium_price) === 'number') {
          n += this.$store.state.shopObj[index].medium_price
        } else {
          n += parseInt(this.$store.state.shopObj[index].medium_price)
        }
      })
      return n.toFixed(2);
    }
  },
  components: {
    Popup
  },
  data() {
    return {
      shopShow: false,
      fullscreenLoading: false,
      popupVisible: false,
      popupContent: [],
      unUse: false
    }
  },
  methods: {
    // 更新用户信息
    setUserInfo() {
      this.curlGet('/api/user/info').then((res) => {
        if (res.data.code) {
          this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
          this.$user_info = res.data.data
          console.log(this.$user_info);
        }
      })
    },
    shopFn() {
      this.shopShow = true
    },
    // 预览
    subFn1() {
      // 效验标题状态
      const titleStatus = fill_title_rules(this.$store.state.FillForm.title)
      console.log(titleStatus);
      if (!titleStatus) {
        return
      }
      if (this.$store.state.FillForm.title == '') {
        return this.$message({
          type: 'warning',
          message: '请填写稿件标题',
        })
      }
      if (this.$store.state.FillForm.content == '' && this.$store.state.FillForm.url == '') {
        return this.$message({
          type: 'warning',
          message: '链接/内容须选填一项',
        })
      }
      this.$store.state.fillActive = true
      this.verifyTest()
    },
    subFn2() {
      this.unUse = true
      if (this.$user_info.money - this.totalMoney < 0) {
        // this.$message({
        // 	type: 'warning',
        // 	message: '余额不足,请充值',
        // })
        this.$alert('<p style="color: #333;font-size: 1.1rem;">余额不足,<a style="color: #108cdd;font-weight: bold;text-decoration: none;" href="https://www.qixuantong.com/user/refill" target="_blank">点击跳转至充值</a></p><p>充值完成后可返回此页面继续提交订单</p>', '提示', {
          dangerouslyUseHTMLString: true,
          showCancelButton: true,
          confirmButtonText: '确认充值完成'
        }).then(res => {
          this.setUserInfo()
        })
      } else {
        this.fullscreenLoading = true
        let data = {}
        data.title = this.$store.state.FillForm.title
        data.content = this.$store.state.FillForm.content

        if (this.$store.state.FillForm.limit_time != '') {
          data.limit_time = this.$store.state.FillForm.limit_time / 1000
        }
        if (this.$store.state.FillForm.url != '') {
          data.url = this.$store.state.FillForm.url
        }
        if (this.$store.state.FillForm.file != '') {
          data.file = this.$store.state.FillForm.file
        }
        if (this.$store.state.FillForm.remark != '') {
          data.remark = this.$store.state.FillForm.remark
        }
        if (this.$store.state.FillForm.cover_image != '') {
          data.cover_image = this.$store.state.FillForm.cover_image
        }
        if (this.$store.state.FillForm.video_url != '') {
          data.video_url = this.$store.state.FillForm.video_url
        }
        if (this.$store.state.FillForm.original_url != '') {
          data.original_url = this.$store.state.FillForm.original_url
        }
        if (this.$store.state.shopObj[0].category_id) {
          data.medium_ids = []
          // console.log('是否来自权益包',this.$store.state.shopObj)
          for (let i = 0; i < this.$store.state.shopObj.length; i++) {

            console.log(i)
            if (this.$store.state.shopObj[i].right_package_id) {
              data.medium_ids.push({
                id: this.$store.state.shopObj[i].id,
                right_package_id: this.$store.state.shopObj[i].id,
                type: this.$store.state.shopObj[i].check,
              })
            } else {
              data.medium_ids.push({
                id: this.$store.state.shopObj[i].id,
                type: this.$store.state.shopObj[i].check,
              })
            }

          }
          // 判断是否为发布媒介订单,如果是,则把data.medium_ids转成JSON
          data.medium_ids = JSON.stringify(data.medium_ids)
        } else if (!this.$store.state.shopObj[0].category_id) {
          data.package_ids = ''
          for (let i = 0; i < this.$store.state.shopObj.length; i++) {
            data.package_ids += `${this.$store.state.shopObj[i].id},`
          }
        }

        if (this.$store.state.shopObj[0]) {
          data.category_id = this.$store.state.shopObj[0].category_id
        }
        console.log('下单请求参数', data)
        this.curlPost('/api/users/order/add', data).then((res) => {
          if (res.data.code) {
            // 更新用户数据
            this.setUserInfo()
            this.$message({
              type: 'success',
              message: '支付成功',
            })
            let query = {}
            if (this.$store.state.FillForm.limit_time) {
              query.time = true
            }
            this.$store.state.fillActive = true
            if (this.$store.state.shopObj[0].category_id) {
              this.$store.commit('SETACTIVEMENU', '/user/MediaOrder')

              this.$store.state.mediaOrder.category_id = data.category_id
              this.$router.push({
                path: '/user/MediaOrder',
                query: query
              })
            } else {
              this.$store.commit('SETACTIVEMENU', '/user/PackageOrder')
              this.$router.push({
                path: '/user/PackageOrder',
                query: query
              })
            }
            setTimeout(() => {
              this.$store.state.FillForm = empty()
              this.$store.state.shopObj = []
            }, 1000)
          } else {
            this.$message({
              type: 'warning',
              message: res.data.msg,
            })
          }
        })
        this.fullscreenLoading = false
        var timer = setTimeout(() => {
          this.unUse = false
        }, 1000)
        timer()
      }
    },
    // 删除
    deleteFn(index) {
      this.$store.state.shopObj.splice(index, 1)
      if (this.$store.state.shopObj.length == 0) {
        this.shopShow = false
      }
    },
    // 选择媒介
    subFn3() {
      const titleStatus = fill_title_rules(this.$store.state.FillForm.title)
      if (!titleStatus) {
        return
      }
      if (this.$store.state.FillForm.title == '') {
        return this.$message({
          type: 'warning',
          message: '请填写稿件标题',
        })
      }
      if (this.$store.state.FillForm.content == '' && this.$store.state.FillForm.url == '') {
        return this.$message({
          type: 'warning',
          message: '链接/内容须选填一项',
        })
      }
      this.$store.state.fillActive = false

      this.verifyTest()
    },
    // 关闭敏感词弹窗
    close() {
      this.popupVisible = false;
    },
    // 效验文章是否有敏感字
    verifyTest() {
      this.fullscreenLoading = true
      this.popupContent = []
      if (this.$store.state.FillForm.content) {
        this.curlGet('/api/index/website').then(res => {
          res.data.data.sensitive_word.forEach((item, index) => {
            if (this.$store.state.FillForm.content.indexOf(item) > 0) {
              this.fullscreenLoading = false
              this.popupContent.push(item)
              return this.popupVisible = true
            }
          })
        })
      }
      setTimeout(() => {
        this.fullscreenLoading = false
        if (this.popupContent.length == 0) {
          if (!this.$store.state.fillActive) {
            this.goMedia()
          } else {
            this.$router.push('/user/SetMenu/ManuscriptPreview')
          }


        }
      }, 800)
    },
    nullDate() {
      this.$store.state.FillForm = {
        cover_image: '', //封面
        title: '',
        content: '', //编译器内容
        limit_time: '', //时间选择器
        file: '',
        url: '',
        video_url: '',
        original_url: '', //原文链接
        remark: '',
        medium_ids: '',
        package_ids: '',
      }
      this.$store.state.shopObj = []
    },
    goMedia() {
      let id = 0
      if (this.$route.query.id) {
        id = this.$route.query.id
      } else {
        id = `${this.$store.state.FillForm.category_id}`
      }
      switch (id) {
        case '0':
          this.$router.push('/user/SetMenu/SetMenuList')
          break;
        case '1':
          this.$router.push('/user/user_media/News')
          break;
        case '2':
          this.$router.push('/user/user_media/UserWeMedia')
          break;
        case '3':
          this.$router.push('/user/user_media/UserPaperMedia')
          break;
        case '4':
          this.$router.push('/user/user_media/UserOfficialAccounts')
          break;
        case '5':
          this.$router.push('/user/user_media/UserWb')
          break;
        case '6':
          this.$router.push('/user/user_media/UserRedBook')
          break;
        case '7':
          this.$router.push('/user/user_media/UserShortVideo')
          break;
        case '8':
          this.$router.push('/user/SetMenu/SetMenuList')
          break;
        default:
          break;
      }
    }
  },

}
</script>

<style lang="scss" scoped>
@import '@/scss/fill_set';
</style>