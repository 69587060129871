import { Message } from 'element-ui'
export function fill_title_rules(title) {
    console.log(title);
    if (title.length > 50) {
        Message.error('标题长度在 1 到 50 个字符');
        return false
    } else if (!title.length) {
        Message.error('请填写标题');

    } else {
        return true
    }
}